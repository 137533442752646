<template>
  <div class="bt-anggota pa-5">
    <Header title="Infaq Anggota" titleIcon="mdi-account-group" />

    <div class="mt-5">
      <v-select solo label="Rembug" class="mb-4" hide-details :items="rembug" item-text="cm_name" item-value="cm_code"
        v-model="cm_code" readonly />

      <v-menu ref="menu" v-model="dateShow" :close-on-content-click="false" :return-value.sync="transaction_date"
        transition="scale-transition" offset-y min-width="auto" class="white" disabled>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field solo v-model="transaction_date" label="Tanggal" readonly v-bind="attrs" v-on="on" />
        </template>
        <v-date-picker v-model="transaction_date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dateShow = false"> Cancel </v-btn>
          <v-btn text color="primary"> OK </v-btn>
        </v-date-picker>
      </v-menu>

      <v-text-field solo v-model="infaq" label="Infaq" type="number" />

      <v-row>
        <v-col cols="6" class="pb-0">
          <router-link :to="`/angsuran/kelompok/${cm_code}/${branch_id}/${transaction_date}`">
            <v-btn block class="purple lighten-1 white--text"> Kembali </v-btn>
          </router-link>
        </v-col>
        <v-col cols="6" class="pb-0">
          <v-btn block class="purple lighten-1 white--text" @click="doSave()">
            Simpan
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import helper from "@/utils/helper";
import Toast from "@/components/Toast";
import Header from "../../components/Header.vue";
import { mapGetters } from "vuex";
import services from "@/services";
export default {
  name: "AngsuranAnggota",
  components: {
    Toast,
    Header,
  },
  data() {
    return {
      overlay: false,
      rembug: [],
      cm_code: null,
      branch_id: null,
      transaction_date: null,
      dateShow: false,
      infaq: 0,
      alert: {
        show: false,
        msg: "",
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    overlay: {
      handler: function (newValue) {
        if (newValue) {
          this.overlay = true;
        } else {
          this.overlay = false;
        }
      },
      immediate: true,
    }
  },
  methods: {
    ...helper,
    async getRembug() {
      try {
        let payload = new FormData();
        payload.append("fa_code", this.user.fa_code);
        payload.append("hari_transaksi", '99');

        this.overlay = true;
        const response = await services.infoRembug(payload, this.user.token);
        if (response.status === 200) {
          if (Array.isArray(response.data.data)) {
            this.rembug = response.data.data;
          } else {
            this.alert = {
              show: true,
              msg: "Rembug tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      } finally {
        this.overlay = false;
      }
    },
    async doSave() {
      this.overlay = true;

      let payload = new FormData();

      payload.append('cm_code', this.cm_code);
      payload.append('transaction_date', this.transaction_date);
      payload.append('infaq', this.infaq);

      try {
        let req = await services.transactionInfaq(payload, this.user.token);
        if (req.data.status === true) {
          this.alert = {
            show: true,
            msg: "Berhasil",
          };
          setTimeout(() => {
            this.$router.push(`/angsuran/kelompok/${this.cm_code}/${this.branch_id}/${this.transaction_date}`);
          }, 1500);
        } else {
          this.alert = {
            show: true,
            msg: req.data.msg,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }

      this.overlay = false;
    },
  },
  mounted() {
    this.cm_code = this.$route.params.cm_code;
    this.branch_id = this.$route.params.branch_id;
    this.transaction_date = this.$route.params.transaction_date;
    this.infaq = this.$route.params.infaq;

    this.getRembug();
  },
};
</script>
